import React, { useState } from "react";
import UploadForm from '../components/uploadForm';
import Layout from "../components/layout";


const UploadPage = () => {
    return (
        <>
        <Layout>
            <UploadForm />
        </Layout>
        </>
    );
};

export default UploadPage;