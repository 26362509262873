import React from 'react'
import "../styles/findVG.css"
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as Check} from '../icons/Check.svg';
import Example from './immunizationExample';


function findVG() {
    return (
        <div className='find-wrapper'>
            <div className='left-find' >
                <span className='top-title'>Why Vaccine Genie</span>
                <span className='bottom-title'>Vaccines prevent illness</span>

                <div className='body-info'>

                    <div className='sub-text'>Vaccine Genie simplifies global vaccine record management with an easy-to-use multilingual app that captures, translates, retrieves, and securely shares vaccination records. It provides a hassle-free solution for expats, international students, and travelers, saving time and money while ensuring timely access to critical vaccination information.</div>
                
                    <div className='checked-info'> <Check/> Missing vaccination records can delay students from starting schools and camps on time  </div>
                    <div className='checked-info'> <Check/> Language barriers makes translating vaccines difficult  </div>

                    <button className='but' id="about-us" style={{ display: 'none' }}>Download the app</button>
                </div>

            </div>

            <div className='right'>
                <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic" style={{ display:'none' }}>
                        Select Language
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">English</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Chinese</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Spanish</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Korean</Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>

                <Example/>
                
            </div>

            
            

        </div>
    );
}

export default findVG