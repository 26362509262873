import React, { useState } from "react";
import Team from '../components/teamMembers';
import Layout from "../components/layout";



const TeamsPage = () => {
    return (
        <Layout>
            <Team />
        </Layout>
    );
};

export default TeamsPage;