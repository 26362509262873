import {React, Component} from "react";
import Card from './businessCardUI'

import '../styles/serviceCards.css'

class Cards extends Component {
    render() {
        return(
            <div className="wrapper">
                <Card stat="$250 Million" title="Total Addressable Market" body="" />
                <Card stat="$100 Million" title="Serviceable Addressable Market" body="" />
                <Card stat="$50 Million" title="Serviceable Obtainable Market" body="" />
            </div>
        )
    }

}

export default Cards;