import React from 'react';
import { Navbar, Nav, Button, Container, NavDropdown } from 'react-bootstrap';
import { ReactComponent as Logo} from '../icons/Logo.svg';
import "../styles/homeNavBar.css"


function HomeNavBar() {
  return (
    <Navbar className="home-navbar" sticky="top" bg="white" data-bs-theme="light">
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Navbar.Brand className="ml-4" href="/"><Logo id="navbar-logo"/></Navbar.Brand>
        
        <Nav className="mx-auto" id="navlinks" style={{display: 'none'}} >
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="#services">Services</Nav.Link>
          <Nav.Link href="/team">Team</Nav.Link>
          <Nav.Link href="#work">Work</Nav.Link>
        </Nav>

        <Navbar.Brand className="ml-4" href="#home"><Logo id="navbar-logo" style={{ visibility: 'hidden' }}/></Navbar.Brand>


          <button className='but' id="signin" style={{ display: 'none' }}>Sign In</button>
          <button className='but' id="signup" style={{ display: 'none' }}>Sign Up</button>

      </Container>
    </Navbar>
  );
}

export default HomeNavBar;
