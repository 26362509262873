import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ArsheyaPhoto from '../assets/Arsheya Raj Final.jpg';
import EvelynPhoto from '../assets/Evelyn Fang.png';
import NathanPhoto from '../assets/Nathan.jpg';
import PaigePhoto from '../assets/Paige Han.jpeg';
import LukePhoto from '../assets/luke.jpg';
import AdelinePhoto from '../assets/Adeline.jpg';
import ShamilPhoto from '../assets/Shamil.jpeg';
import VedikaPhoto from '../assets/Vedika Bataan.jpeg';


const TeamGrid = () => {
const teamMembers = [
  
  {
    photo: EvelynPhoto,
    name: "Dr. Evelyn Fang",
    role: "Founder & CEO",
    description: "Boarded in Family and Internal Medicine in the United States. UW graduate student in Clinical Informatics and Patient Centered Technology (CIPCT) program. Worked in China, familiar with travel vaccine needs and advocate of primary prevention through vaccines.",
    linkedinUrl: "https://www.linkedin.com/in/evelyn-fang-b2307798/"
  },

  {
    photo: ArsheyaPhoto,
    name: "Arsheya Rag",
    role: "Founder & CTO",
    description: "Graduate student at University of Washington, MS in Computer Science and Software Engineering - School of STEM (Bothell). Worked in India at as business technology analyst and as freelance application developer.",
    linkedinUrl: "https://www.linkedin.com/in/rajarsheya/"
  },
  
  {
    photo: PaigePhoto,
    name: "Paige Han",
    role: "Product Designer",
    description: "MS in Human Centered Design & Engineering candidate. Leveraging experience in usability and product design in software engineering.",
    linkedinUrl: "https://www.linkedin.com/in/hyeji-han/"
  },

  {
    photo: LukePhoto,
    name: "Luke Sandoval",
    role: "Web Developer",
    description: "BS in Computer Science",
    linkedinUrl: "https://www.linkedin.com/in/luke-samuel-sandoval/"
  },

  {
    photo: AdelinePhoto,
    name: "Adeline Li",
    role: "UX Designer",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/qili0103/"
  },

  {
    photo: NathanPhoto,
    name: "Nathan Alexander",
    role: "Mobile Developer",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/nathan-alexander1/"
  },

  {
    photo: ShamilPhoto,
    name: "Shamil Khamrayev",
    role: "QA/QC",
    description: "NA",
    linkedinUrl: "https://www.linkedin.com/in/shamil-khamrayev/"
  },

  {
    photo: VedikaPhoto,
    name: "Vedika Bataan",
    role: "N/A",
    description: "N/A",
    linkedinUrl: "https://www.linkedin.com/in/vedika-bataan-8b76b6297/"
  },
  
];

return (
  <Container className="py-4">
    <Row className="justify-content-center g-4">
      {teamMembers.map((member, index) => (
        <Col 
          key={index}
          xs={12}      // On extra small devices (phones), take full width (1 column)
          sm={6}       // On small devices, take half width (2 columns)
          md={4}       // On medium devices, take 1/3 width (3 columns)
          lg={3}>
          <div className="card h-100 w-200 shadow">
            <div className="text-center p-3">
              <div style={{ width: '150px', height: '150px', margin: '0 auto' }}>
                <img
                  src={member.photo}
                  alt={member.name}
                  className="rounded-circle w-100 h-100 object-fit-cover"
                />
              </div>
              <div className="card-body">
                <h5 className="card-title mb-1">{member.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{member.role}</h6>
                
                {member.linkedinUrl && (
                  <a 
                    href={member.linkedinUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary btn-sm"
                  >
                    LinkedIn
                  </a>
                )}
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  </Container>
);
};

export default TeamGrid;